let form = document.querySelector('form')
let button = document.querySelector('input[type=submit]')
let buttonText = button.textContent

form.addEventListener("submit", async function (e) {
    
    button.disabled = true
    // button.textContent = 'Chargement...'
    e.preventDefault()

    let data = new FormData(form)
    
    try {
    // let response = await fetch(form.getAttribute('action'), {
        let response = await fetch("/contact", {
          method: 'POST',
          headers: {
            'X-Requested-With': 'xmlhttprequest'
          },
          body: data
        })
        let responseData = await response.json()
        if (response.ok === true) {
            let inputs = form.querySelectorAll('input, textarea')
            for (let i = 0; i < inputs.length; i++) {
              inputs[i].value = ''
            }
            let div = document.createElement("div")
            div.className = 'info-message'
            div.innerHTML = "Votre message a été envoyé avec succès. Nous prendrons contact avec vous dans les plus brefs délais"
            
            form.append(div)
        } else {
            alert('Erreur d\'envoi')
        }
    } catch (e) {
        alert(e)
    }
    // Dans tous les cas on permet la soumission du formulaire à nouveau
    button.disabled = false
    // button.textContent = "Envoyer"  
    setTimeout(function() {
      // form.reset()
      location.reload()
    }, 2000);
    // document.querySelector('#protection').checked = false;
    button.value = "Envoyer"
})